import React from "react";
import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";

const Terms = () => {
  let links = [
    ["/platform", "Home"],
    ["/terms", "Terms & Conditions"],
  ];

  return (
    <ShopLayout>
      <div className="border-b-2 border-dashed border-gray-300">
        <Breadcrumbs links={links} />
        <div className="container lg:pl-12 my-8">
          <div>
            <h1 className="font-display text-blue-900 text-4xl mb-6">
              TERMS & CONDITIONS OF USE MEIA PATA
            </h1>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Preamble The company MEIA PATA, LDA. (hereinafter the “Company” or
              “MEIA PATA”), a simplified joint-stock company with a capital of
              200,00€, under the number 513 830 898, whose registered office is
              located at Rua José Cavadas, 8 4780-486, Santo Tirso hereafter
              referred to as " MEIA PATA " provides on its website networking
              platforms allowing professional Buyers to buy Products directly
              from professional Sellers, hereinafter referred to as the
              “Platforms”.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              The Website is exclusively dedicated to the sale of Products for
              professionals in the retail trade, and under no circumstances for
              sale to private individuals.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              The articles relating to the Terms and Conditions of Use apply to
              all Users and visitors, whether or not they are registered on the
              Website.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 1. Definitions In these Terms and Conditions, words or
              expressions beginning with a capital letter, used in the singular
              or plural form, have the meaning given below:
              <ul className="ml-10 list-disc">
                <li>
                  Buyers: Professionals who buy Products for resale to the
                  general public.
                </li>
                <li>
                  Products: means the products and/or services offered for sale
                  by MEIA PATA
                </li>
                <li>
                  Service(s): all the services provided online by MEIA PATA to
                  Users via the Website.
                </li>
                <li>
                  Website: means the website https://www.b2b.meiapata.com
                  published by the company MEIA PATA and constituting the
                  technical support of the Platform.
                </li>
                <li>
                  User: means any natural or legal person, Buyer, acting
                  exclusively for professional purposes, registered on the
                  https://www.b2b.meiapata.com website and who accesses and/or
                  uses the Platforms.
                </li>
              </ul>
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 2. Scope of these Terms and Conditions of Use. <br />
              The purpose of these Terms and Conditions of Use hereinafter
              (“TCU”) is to define the conditions under which any User uses the
              services provided by MEIA PATA. The TCU are intended to apply
              exclusively between MEIA PATA and the User wishing to access
              and/or use the Platform. Any use of the Service by the User
              automatically entails the application of these Terms and
              Conditions of Use. The current version of the Terms and Conditions
              of Use and Privacy Policy can be viewed at any time by clicking on
              the “Terms & Conditions” link. If necessary, MEIA PATA reserves
              the right to modify and update these Conditions of Use and the
              Privacy and Personal Data Protection Policy at any time. Whenever
              possible, MEIA PATA will endeavour to inform Users in an
              appropriate manner.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 3. Registration - Buyer Account Creation <br />
              Access to the Website is reserved for Buyers who can provide
              evidence of a professional activity in line with the field of
              activity of the Platforms accessible from the Website. To access
              and use the Services, each User must create an account by
              providing the following information:
              <ul className="ml-10 list-disc">
                <li>Full Name</li>
                <li>Email</li>
                <li>Tax Identifier Number</li>
                <li>Password</li>
                <li>Company Name</li>
                <li>Company Website</li>
                <li>Address</li>
                <li>Country</li>
                <li>Region</li>
              </ul>
              By registering, the User guarantees to provide accurate and
              up-to-date information relating to his/her identity and contact
              details. Any account registration will be reviewed by MEIA PATA.
              MEIA PATA reserves the right to reject the application, without
              being required to provide any justification for such rejection. In
              the event of doubt or necessary verification, MEIA PATA may ask
              the User to provide proof of identity, or any other document that
              may justify its activity, in order to verify the conformity of the
              User’s declarations. The User undertakes to register only once
              under his/her real name and undertakes not to multiply
              registrations under a pseudonym and/or multiple email addresses.
              Once registration has been confirmed, the Buyer will have access
              to all the Platforms hosted by the Website, the list of which can
              be consulted at https://www.b2b.meiapata.com
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 4. Access to the Website <br />
              Access to and consultation of the Online Catalogues on the Website
              are open to any Buyer with a Buyer account. The right to access
              the Website granted to the Buyers is a limited, revocable,
              non-exclusive and non-transferable right. However, certain offers
              may not be accessible to Buyers due to the Sellers’ choice to
              privatise their catalogue. This feature gives Sellers the freedom
              to individually allow a Buyer to view their Catalogue. Access to
              the Services by Users with suspicious activity will be suspended
              while the Company carries out the necessary verification. In
              particular, creating several User Accounts by the same User,
              creating erroneous information, or creating a fictitious or
              misleading professional activity will be considered suspicious.
              Fraudulent User Accounts will be deleted after verification,
              without the User being entitled to claim any compensation for any
              direct or indirect damage caused by said deletion.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 5. Password and Username <br />
              Each User is solely responsible for the security and use of his or
              her password and username. The Company cannot be held liable for
              any damage caused in the event that the confidentiality of the
              password is compromised, or if its reliability can be questioned.
              Each User undertakes to keep his or her password as well as his or
              her identifier allowing connection to the Website accessible via
              https://www.b2b.meiapata.com strictly confidential, and not to
              communicate them to a third party. In the event that a User
              believes that the confidentiality of his or her passwords and
              login could be compromised or that a third party is using them,
              the User must promptly inform MEIA PATA, by sending a message to
              the following address: hello@meiapata.pt In the event that the
              Buyer does not use his or her passwords and login in accordance
              with the MEIA PATA will be entitled to close the Buyer’s personal
              account without prior notice and without this entitling the User
              to any right to compensation.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 6. Protection of Personal Data <br />
              For further information on the treatment of personal data, MEIA
              PATA encourages you to consult its privacy policy. The User
              remains exclusively and entirely responsible for the processing of
              personal data which is carried out by the User on the User’s own
              behalf and for which the User has been made the recipient,
              directly or indirectly, through the use of the Services.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 7. Availability of the Website <br />
              The Website is accessible 24 hours a day, 7 days a week, except in
              the event of an interruption, scheduled or not scheduled, for
              maintenance purposes or in the event of force majeure as defined
              in Article 8. Any interruption of service for one of these reasons
              will not entitle Users to compensation of any nature whatsoever
              and for any reason whatsoever. Being subject to a best efforts
              obligation, MEIA PATA cannot be held liable for any damage of any
              nature whatsoever resulting from the unavailability of the
              Website. Thus, MEIA PATA endeavours to implement all reasonable
              diligence in accordance with professional standards in order to
              limit the risks of intrusion and hacking of its servers and to
              secure connections. However, MEIA PATA cannot guarantee full
              security and declines all responsibility for the actions of
              malicious third parties breaking into the servers or depositing
              viruses or malware that may infect MEIA PATA’s contents or emails.
              Nevertheless, MEIA PATA undertakes to do what is necessary,
              promptly, to limit the consequences of any security breaches and
              to repair them as soon as they are detected. MEIA PATA will not be
              held liable for interruptions of services due to a malfunction of
              the network operators and more generally for any system whose
              operation is not under its control, such as computer, telephone
              and Internet networks, nor for interruptions of services. MEIA
              PATA will not be held liable for the lack of performance of the
              Website and/or the software used or downloaded; for the loss of
              data or services resulting from the failure to meet deadlines, the
              modification, suspension or interruption of the Website and/or its
              Service, nor for the consequences resulting directly or indirectly
              from the transmission of viruses via its servers. Furthermore, due
              to the characteristics and limitations of the Internet network,
              which the User declares to be fully aware of, MEIA PATA cannot be
              held liable for, in particular, difficulties or impossibility of
              access to the Website and/or the Service due to the saturation of
              the network at certain periods. MEIA PATA makes no commitment with
              regard to the performance of the Website and the Service, taking
              into account, in particular, the quality of the Internet network
              and/or technical configurations. No interruption or slowdown in
              the operation of the Website will give rise to claims of any
              nature whatsoever, nor a fortiori to compensation, particularly in
              the event of a loss of connections, data or information of any
              nature whatsoever.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 8. Limitation of Liability <br />
              It is expressly recalled that the Company’s business is to connect
              professionals with each other in order to facilitate the taking of
              orders. Subject to an obligation of means, MEIA PATA applies its
              best efforts to provide accurate information.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 9. Major Force <br />
              Any event beyond the control of the parties and which it is
              impossible to reasonably foresee and overcome will be considered
              as an event of major force in accordance with the provisions of
              the Portuguese Civil Code. The liability of the Company will be
              entirely excluded if the non-performance of any or all of the
              obligations for which it is responsible and which are provided for
              by these conditions, result from a case of major force. In the
              event of force majeure, the Company will be exempted from the
              execution of the contractual obligations to which it is bound
              without the User being able to claim any compensation for any
              prejudice whatsoever.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 10. Intellectual Property Rights <br />
              The graphic charter and the elements composing the Website, as
              well as all the Platforms, current or future, composing the
              Website, are protected by the legislation relating to intellectual
              property currently in force in Portugal, for the whole world. MEIA
              PATA is the owner of all the related rights. The entire content of
              the Website and the Platforms, in particular their editorial
              content, MEIA PATA brand, photos, logos, images, sounds, videos or
              illustrations as well as the structure, databases composing MEIA
              PATA website of which MEIA PATA is the author, publisher and
              producer, is protected, in particular by intellectual property
              law. Acceptance of these Terms and Conditions of Use does not
              imply any transfer of any kind of intellectual property rights
              over the elements belonging to MEIA PATA and/or its partners for
              the benefit of the User. The temporary accessibility of the
              Website under the conditions provided for in these Terms and
              Conditions of Use cannot be analysed as the transfer of any
              intellectual property rights to the User, within the meaning of
              the Portuguese Intellectual Property Code. The User will refrain
              from reproducing any element of the Website, or any documentation
              relating to it, by any means whatsoever, in any form whatsoever
              and on any medium whatsoever. No title, nor any right whatsoever
              on any element of the Website will be obtained by downloading or
              copying elements of the Website. The User only has a personal and
              non-exclusive right to use the Website. Any reproduction,
              representation or distribution of all or part of the Website by
              any means whatsoever and on any medium whatsoever is formally
              prohibited, with the sole exception of the right to reproduce for
              storage for the purpose of representation for backup copies or
              printing on paper in one copy, provided that the integrity of the
              documents is respected. Any other use is subject to the prior and
              express authorisation of the Company. Furthermore, it is indicated
              that the Products for Sale are likely to be covered by
              intellectual property rights which are the sole property of the
              Exhibitors. Exhibitors are personally responsible for protecting
              and enforcing their rights.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 11. Hyperlinks <br />
              The Platform can provide links to other websites or other sources
              of information managed by MEIA PATA. In the event of
              inappropriate, illicit or shocking content, Users will immediately
              inform MEIA PATA, which will promptly remove the links.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 12. Amicable Settlement <br />
              Any dispute relating to the interpretation and/or execution of
              these TCU will, whenever possible, be settled by amicable
              negotiation between the Parties, which the Company will endeavour
              to facilitate. After a period of 30 calendar days, and in the
              absence of an amicable solution, the Parties will then have the
              right to refer the matter to the competent courts. By mutual
              agreement between the parties, this provision will not preclude
              the initiation of any emergency proceedings, by petition or in
              summary proceedings.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 14. Competent Court <br />
              In the event of failure of the amicable settlement and in the
              presence of any dispute, express jurisdiction is attributed to the
              Commercial Court of Santo Tirso, notwithstanding plurality of
              defendants or appeal in warranty, even for emergency or
              precautionary proceedings, in summary proceedings or by petition.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Article 15. Applicable law <br />
              Contractual language The Terms and Conditions of Use of the
              Website are governed by Portuguese Law, regardless of the
              nationality of the User. The language of reading, understanding
              and interpretation of the TCU is English.{" "}
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              <b>LEGAL MENTIONS</b>
              <br />
              Credits
              <br />
              All rights of reproduction or quotation reserved to MEIA PATA,
              LDA. <br />
              Editor <br /> The Site https://www.b2b.meiapata.com is edited and
              marketed by the company MEIA PATA, LDA., registered under number
              513 830 898. <br />
              Tel. : +351 913 343 808
            </p>
          </div>
        </div>
      </div>
    </ShopLayout>
  );
};

export default Terms;
